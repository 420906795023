section:not(.top-bar-section) {
	

	&.posts {

	

		/*background-color: scale-color($primary-color, $lightness: 25%);*/


		a {
			//color: $secondary-color;
		}
	}

	&.contact {

		padding: 0;
		position: relative;
		


		@media #{$medium-up} {
			height: $map-height;
		}
		
	}


	&#footer {
		padding: 2rem 0;
		
		h4 {
			padding-bottom: 1rem;
			margin-bottom: 1rem;
			border-bottom: 1px solid scale-color($primary-color, $lightness: -70%);
		}

	}

	@media #{$small-up} {
		padding: 2rem 0;
	}

	@media #{$medium-up} {
		padding: 2rem 0;
	}

	@media #{$large-up} {
		padding: 3rem 0;
	}
}

