.top-bar {

	//height:70px;
	text-align: center;

	.title-area .name h1 {
		
	}

	.title-area .name a {
		display: block;
		//text-indent: -1000px;
		//background: url('/images/nhhlogotest2.png') no-repeat;
		background-position: 0% 30%;  
		//width: 320px;
		line-height: 4em;
		//background-size: 39px 41px;
		color: $black;
		//margin-left: $column-gutter / 2;
		padding: 0 0.7rem;
		text-transform: uppercase;
		text-shadow: rgba(0, 0, 0, 0.1) 4px 4px 4px;

	}
@media only screen and (min-width: 40em) {

		// .top-bar-section {
		// 	display: inline-block;
		// }
}
		.top-bar-section li.current-menu-item a{
			background-color: $secondary-color;

		}

		.top-bar-section li a{
			height: 4.825rem;
			border-top: 2px solid $white;
			border-left: 1px solid $white;
			border-right: 1px solid $white;
		}
	

}	

#logo {
	text-align: left;
}

#nav{
	border-top: 2px solid $third-color;
	padding: 0;
}