@import url(https://fonts.googleapis.com/css?family=Lato:400,700,400italic);



body {
	.fixed{
		 // position:static;
		 border-bottom: 2px Solid $third-color;
		 border-top: 2px Solid $third-color;
	}

	a {
		color: $black;
	}

	a:hover {
		color: $third-color;
		
	}	

}

img.alignright {
	float: right;
}

h1{
	font-weight: 800;
	color: $black;
	text-align: left;
	padding-bottom: 1em;
}

 h2, h3, h4, h5, h6 {
	
  
  font-weight: 800;
  // font-family: 'Helvetica Neue', sans-serif;
  // line-height: 1.2;
  color: $black;
 /* text-transform: uppercase;
  margin: 0;    scale-color(rgb(0,0,0),*/
  text-align: left;
  
}

h1, h2, h3 {
	text-shadow: rgba(0, 0, 0, 0.1) 4px 4px 4px;

}

#content .main img {

	margin: 0 1rem 1rem 0;

	&.alignright {
		margin: 0 0 1rem 1rem;
	}


}