.sidebar {

	.image, .person {

		img {
			width: 100%;
			margin-bottom: 1rem;
		}

		text-align: center;
	}

}