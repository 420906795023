
section.banner {

	display: block;
	width: 100%;
	position: relative;
	height: 0;
	padding: 23% 0 0 0;
	overflow: hidden;

	background-repeat: no-repeat;
	background-size: cover;
	background-position: center; 

	@media #{$small-only} {
		height: 200px;
		padding: 0;
	}
/*
	@media #{$small-up} {
		height: 200px;
		padding: 0;
	}

	@media #{$medium-up} {
		height: 0;
		padding: 30% 0 0 0;
	}

	@media #{$xxlarge-up} {
		padding: 23% 0 0 0;
	}
*/



	&.full {

		@media #{$small-up} {
			height: 620px;
			padding: 0;

			@media #{$landscape} {
				height: 320px;
			}
		}

		@media #{$medium-up} {
			height: 0;
			padding: $banner-full-height-ratio 0 0 0;
		}		
	}



	div.container {
		
		
		width: 100%;
		position: absolute;
		display: block;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;


		.table {
			display: table;
			height:100%;
			width: 100%;
			.cell {
				height:100%;
				display: table-cell;
				vertical-align: middle;

				.panel {
					float: left;
				}
			}

			.row {
				
			}			
		}

		h1, h2 {
			color: $black;

			
			@media #{$small-up} {
				font-size: 1rem;
			}

			@media #{$medium-up} {
				font-size: 1.3rem;
			}

			@media #{$large-up} {
				font-size: 2rem;
			}
		}
	}


}

section.banner.gmap {
	
	padding: 20% 0 0 0;

	@media #{$xxlarge-up} {
		padding: 15% 0 0 0;
	}

	.map {
		width: 100%;
		height: 100%;
	}
}