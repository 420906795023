
.mapwrap {
	z-index: 1;
	
	width: 50%;
	height: $map-height;
	overflow: hidden;

	

	&.nydalen {
		left:0;
		top:0;
	}

	&.drobak {
		right:0;
		top:0;
	}

	@media #{$small-only} {
		width: 100%;
		position: relative;
	}

	@media #{$medium-up} {
		position: absolute;
		width: 50%;
	}


	.klinikk {
		z-index: 99;
		position: absolute;
		
		width: 100%;


		

		.row {
			max-width: $row-width/2;
			h4 { 
				margin: 0 ;
			}
		}

		&.nydalen {
			.row {
				margin-right: 0;
				margin-left: auto;
			}
		}

		&.drobak {
			.row {
				margin-left: 0;
				margin-right: auto;
			}
		}

		@media #{$small-only} {
			bottom:inherit;
			top: 0;
		}

		@media #{$medium-up} {
			bottom:inherit;
			top: 0;
		}
	}



	.map {
		width: 100%;
		height: $map-height + 40px;		
	}
	
	a {
		color: $black;
		text-decoration: underline;
	}

	ul  {
		margin: 0;
		padding: 0;
		li {
			list-style-type: none;

			padding-left: 22px;
			
			&.title {
				padding-left: 0;
			}

			background-position: 0 6px;
			background-repeat: no-repeat;

			&.address {
				background-image: url(../images/address.svg);
				background-size: 15px;
			}

			&.email {
				background-image: url(../images/email.svg);
				background-size: 15px;
			}

			&.tel {
				background-image: url(../images/telephone.svg);
				background-size: 15px;
			}
		}
	}
}

