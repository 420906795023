
.panel {

	&.black:not(.alpha) {
		@include panel($panel-black-bg);
		border:none;
		
	}

	&.black.alpha {
		@include panel($panel-black-bg-alpha);
		border:none;
		
	}

	&.black.alpha2 {
		@include panel($panel-black-bg-alpha2);
		border:none;
		
	}

	&.black.alpha4 {
		@include panel($panel-black-bg-alpha4);
		border:none;
		
	}


	&.primary:not(.alpha), &.blue:not(.alpha) {
		@include panel($panel-primary-bg);
		border:none;	
		p {
			color: $white;	
		}
	}

	&.primary.alpha, &.blue.alpha {
		@include panel($panel-primary-bg-alpha);
		border:none;
		p {
			color: $white;	
		}	
	}



	&.secondary:not(.alpha) {
		@include panel($panel-secondary-bg, $panel-padding, false);
		border:none;
		
	}

	&.secondary.alpha {
		@include panel($panel-secondary-bg-alpha, $panel-padding, false);
		border:none;
		
	}

	&#footer {
		margin-bottom: 0;
		background-color: $white;
		border-top: 2px solid $third-color;
		border-bottom: 2px solid $third-color;
	}

}

.sosial {
	margin: 10px;
	img {
		height: 40px;
	}
}