$map-height: 350px;

$panel-black-bg: $secondary-color;
$panel-black-bg-alpha: rgba($black,0.6);
$panel-black-bg-alpha4: rgba($black,0.4);
$panel-black-bg-alpha2: rgba($black,0.2);

$panel-primary-bg: $primary-color;
$panel-primary-bg-alpha: rgba($primary-color,0.6);

$panel-secondary-bg: $secondary-color;
$panel-secondary-bg-alpha: rgba($secondary-color,0.6);


$banner-height-ratio: 30%;


$banner-full-height-ratio: 50%;